import React, { useContext, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { MdMenu } from 'react-icons/md'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { MenuContext } from '../../context/MenuContext'
import Logo from '../Logo'
import './Nav.scss'

const NavLink = ({
  name,
  path,
  itemColor,
  itemHoverColor,
  menuOpen,
  toggleMenu,
  pathname,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const onHover = () => setIsHovered(true)
  const onLeave = () => setIsHovered(false)

  if (path === '#contact') {
    return (
      <li key={name}>
        <button
          className='iliad__nav--contact-link'
          onClick={() => {
            scrollTo('#contact')
            menuOpen && toggleMenu()
          }}
          style={{ color: isHovered ? itemHoverColor : itemColor }}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}>
          {name}
        </button>
      </li>
    )
  }
  return (
    <li key={name}>
      <Link
        style={{ color: isHovered ? itemHoverColor : itemColor }}
        to={path}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onClick={() => {
          if (pathname === path) {
            if (menuOpen) {
              toggleMenu()
            }
            scrollTo('#page-section-0')
          }
        }}>
        {name}
      </Link>
    </li>
  )
}

export const NavList = React.memo(
  ({ itemColor, itemHoverColor, toggleMenu, menuOpen, pathname, footer }) => {
    const links = [
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'Process',
        path: '/process/',
      },
      {
        name: 'Products',
        path: '/products/',
      },
      {
        name: 'News',
        path: '/news/',
      },
      {
        name: 'Contact',
        path: '#contact',
      },
    ]
    return (
      <>
        {links.map(({ name, path }) => {
          if (footer && path === '#contact') return null
          return (
            <NavLink
              key={name}
              name={name}
              path={path}
              itemColor={itemColor}
              itemHoverColor={itemHoverColor}
              toggleMenu={toggleMenu}
              menuOpen={menuOpen}
              pathname={pathname}
            />
          )
        })}
      </>
    )
  }
)

export const Nav = React.memo(
  ({
    navColor: itemColor,
    navHover: itemHoverColor,
    logoStyle: logo,
    lockNav,
    logoOnly,
    pathname,
    index,
  }) => {
    const { menuOpen, toggleMenu } = useContext(MenuContext)
    const [isHovered, setIsHovered] = useState(false)
    const onHover = () => setIsHovered(true)
    const onLeave = () => setIsHovered(false)

    const navAddressData = useStaticQuery(graphql`
      query navAddressData {
        businessInfo: file(relativePath: { eq: "business-info.md" }) {
          childMarkdownRemark {
            frontmatter {
              name
              city
              state
              zip
              email
            }
          }
        }
        backgroundImage: file(relativePath: { eq: "city-sketch.jpg" }) {
          id
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `)

    const { name, city, state, zip, email } =
      navAddressData.businessInfo.childMarkdownRemark.frontmatter

    return (
      <>
        {logoOnly ? (
          <div className='iliad__nav--mobile-scroll-to-top'>
            <button
              onClick={() => scrollTo('#page-section-0')}
              style={{ color: isHovered ? itemHoverColor : itemColor }}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}>
              <FaArrowAltCircleUp />
              Back to Top
            </button>
          </div>
        ) : (
          <button
            onClick={() => toggleMenu()}
            className={`iliad__nav--toggle${
              menuOpen ? ' ag__nav--toggle-open' : ''
            }`}
            aria-expanded={menuOpen}
            style={{
              color: itemColor,
              position: lockNav ? 'absolute' : 'fixed',
            }}>
            <span>
              {menuOpen ? '' : <MdMenu size='1.44rem' />}
              {menuOpen ? ' Close' : ' Menu'}
            </span>
          </button>
        )}
        <div
          className='iliad__nav'
          style={{ position: lockNav ? 'absolute' : 'fixed' }}>
          <div className='iliad__nav--logo'>
            <Link to='/' aria-label='Home Page'>
              <div className='nav__logo'>
                <Logo theme={logo} alt='Iliad Epic Grow Logo' />
              </div>
            </Link>
          </div>
          {!logoOnly && (
            <>
              <nav className='iliad__nav--desktop'>
                <ul>
                  <NavList
                    itemColor={itemColor}
                    itemHoverColor={itemHoverColor}
                    toggleMenu={toggleMenu}
                    menuOpen={menuOpen}
                    pathname={pathname}
                  />
                </ul>
              </nav>
              <nav
                className='iliad__nav--mobile'
                style={{
                  opacity: menuOpen ? '100%' : '0%',
                  visibility: menuOpen ? 'visible' : 'hidden',
                }}>
                <ul id={`nav-menu-${index}`}>
                  <NavList
                    itemColor={itemColor}
                    itemHoverColor={itemHoverColor}
                    toggleMenu={toggleMenu}
                    menuOpen={menuOpen}
                    pathname={pathname}
                  />
                </ul>
                <div className='iliad__nav--copyright'>
                  <address>
                    <span>{name}</span>
                    <div className='iliad__address--flex'>
                      <span className='iliad__address--block'>
                        <span>
                          {city}, {state} {zip}
                        </span>
                        <br />
                      </span>
                      <span className='iliad__contact--block'>
                        <br />
                        <a href={`mailto:${email}`}>{email}</a>
                      </span>
                    </div>
                  </address>
                  <span>
                    © 2020-21 RGR Industries Inc. All rights reserved.
                    <br />
                    <Link to='/terms-of-service/'>Terms of Service</Link>
                  </span>
                </div>
              </nav>
            </>
          )}
        </div>
      </>
    )
  }
)

export default Nav
