import React from 'react'
import * as styles from './SnapScrollContainer.module.scss'

/**
 * SnapScrollContainer is a container that snaps to the nearest child element when scrolling.
 * @todo move styles for snap scroll stuff here instead of the global app style sheet.
 */
export const SnapScrollContainer = React.forwardRef(({ children }, ref) => {
  return (
    <div className={styles.main} ref={ref}>
      <div className={styles.snapContainer}>{children}</div>
    </div>
  )
})

SnapScrollContainer.displayName = 'SnapScrollContainer'

export default SnapScrollContainer
